export const environment = {
  ubicacion: 'AIzaSyCCWUTqGTa4Re2HpYD5tVLe7rMu7-ag8Lk',///airview
  //ubicacion: 'AIzaSyAvkGtdpMMditm_bMoBE8lM5RN6YD1-NsU',///accesa
  production: true,
  firebase: {
    apiKey: "AIzaSyBFgSdV43wAN9rD-wK44nSkGwHuhoHzIjk",
    authDomain: "airview-a1375.firebaseapp.com",
    databaseURL: "https://airview-a1375.firebaseio.com",
    projectId: "airview-a1375",
    storageBucket: "airview-a1375.appspot.com",
    messagingSenderId: "1004949844260"
  }
};